import React, { useState, createContext, useCallback, ReactNode, useEffect } from 'react';

import { LocalStorageService } from '../services';
import { Theme, useMediaQuery } from '@mui/material';

interface IMenuOption {
  key: string;
  path: string;
  icon?: string;
  label: string;
  subMenu?: IMenuOption[];
}
interface IDrawerContextData {
  isDrawerPersistent: boolean;
  menuOptions: IMenuOption[];
  toggleDrawer(): void;
  drawerWidth: number;
  isOpen: boolean;
  isDrawerKeepOpen: boolean;
  isDrawerOpen: boolean;
  toggleDrawerKeepOpen(): void;
  toggleDrawer(): void;
  closeDrawer(): void;
  openDrawer(): void;
  isDrawerMobile: boolean;

}
export const DrawerContext = createContext<IDrawerContextData>({} as IDrawerContextData);

export const DrawerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isDrawerKeepOpen, setIsDrawerKeepOpen] = useState(LocalStorageService.get<boolean>('keepDrawerOpen'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(LocalStorageService.get<boolean>('keepDrawerOpen'));
  const [drawerWidth] = useState(35);
  const menuOptions: IMenuOption[] = [
    {
      icon: '',
      key: 'pagina',
      path: '/',
      label: '',
    },
    {
      icon: 'home',
      key: 'pagina-inicial',
      path: '/pagina-inicial',
      label: 'Página inicial',
    },
    {
      icon: 'domain',
      key: 'tenants',
      path: '/tenants',
      label: 'Tenants',
    },
    {
      icon: 'inventory',
      key: 'produtos',
      path: '/produtos',
      label: 'Produtos',
    },
    {
      icon: 'assignmentturnedin',
      key: 'licenciamentos',
      path: '/licenciamentos',
      label: 'Licenciamentos',
    },
    {
      icon: 'commenticon',
      key: 'consulta-feedbacks',
      path: '/consulta-feedbacks',
      label: 'Feedbacks',
    },
    {
      icon: 'timeline',
      key: 'consulta-telemetria',
      path: '/consulta-telemetria',
      label: 'Telemetria',
    },
    {
      icon: 'timeline',
      key: 'telemetria-gvcollege',
      path: '/telemetria-gvcollege',
      label: 'Telemetria GVcollege',
    },
    {
      icon: 'history_edu',
      key: 'termos-padrao',
      path: '/termos-padrao',
      label: 'Termos padrão',
      subMenu: [
        {
          icon: 'list_icon',
          key: 'termos-padrao',
          path: '/termos-padrao',
          label: 'Listagem de termos',
        },
        {
          icon: 'list_icon',
          key: 'categorias-termo',
          path: '/categorias-de-termo',
          label: 'Listagem de Categorias',
        }
      ],
    },
    {
      icon: 'article_icon',
      key: 'relatorios',
      path: '/relatorios',
      label: 'Relatórios',
      subMenu: [
        {
          icon: 'sync_icon',
          key: 'sincronizar-relatorios',
          path: '/relatorios',
          label: 'Sincronizar relatórios',
        },
        {
          icon: 'list_icon',
          key: 'funcoes',
          path: '/funcoes',
          label: 'Listagem de funções',
        }
      ],
    },
    {
      icon: 'article_icon',
      key: 'topic-manager',
      path: '/topic-manager',
      label: 'GVTopicManager',
      subMenu: [
        {
          icon: 'list_icon',
          key: 'redirecionamento-topicos',
          path: 'redirecionamento-topicos',
          label: 'Redirecionamento de tópicos'
        },
        {
          icon: 'list_icon',
          key: 'topicos-tenants',
          path: 'topicos-tenants',
          label: 'Tópicos de tenants'
        }
      ]
    }
    /* ROBO - Nova linha acima */
  ];

  /** Retora do localstorage se o drawer está aberto ou fechado */
  const getDrawerStateFromStorage = useCallback(() => {
    return Boolean(LocalStorageService.get('DRAWER_STATE'));
  }, []);

  /** Salva um novo estado para o drawer no localstorage */
  const setDrawerStateFromStorage = useCallback((newState: boolean) => {
    LocalStorageService.set('DRAWER_STATE', newState);
  }, []);

  const isDrawerMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  useEffect(() => {
    if (isDrawerMobile) {
      setIsDrawerKeepOpen(false);
      setIsDrawerOpen(false);
    }
  }, [isDrawerMobile]);
  useEffect(() => {
    if (!isDrawerMobile && isDrawerOpen) {
      setIsDrawerKeepOpen(true);
    }
  }, [isDrawerMobile]);

  /** Alterna o contexto do drawer entre aberto e fechado */
  const handleToggleDrawer = useCallback(() => {
    setIsDrawerOpen(oldState => !oldState);
  }, []);

  const handleToggleDrawerKeepOpen = useCallback(() => {
    setIsDrawerKeepOpen(oldState => {
      LocalStorageService.set('keepDrawerOpen', !oldState);
      return !oldState;
    });
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  const handleOpenDrawer = useCallback(() => {
    setIsDrawerOpen(true);
    return;

  }, []);

  // const [drawerState, setDrawerState] = useState<IDrawerContextData>({
  //   isOpen: getDrawerStateFromStorage(),
  //   isDrawerPersistent: false,
  //   drawerWidth,
  //   menuOptions,
  //   isDrawerKeepOpen,
  //   isDrawerOpen,
  //   openDrawer: handleOpenDrawer,
  //   closeDrawer: handleCloseDrawer,
  //   toggleDrawer: handleToggleDrawer,
  //   toggleDrawerKeepOpen: handleToggleDrawerKeepOpen,
  //   isDrawerMobile
  // });

  return (
    <DrawerContext.Provider value={
      {
        isOpen: getDrawerStateFromStorage(),
        isDrawerPersistent: false,
        drawerWidth,
        menuOptions,
        isDrawerKeepOpen,
        isDrawerOpen,
        openDrawer: handleOpenDrawer,
        closeDrawer: handleCloseDrawer,
        toggleDrawer: handleToggleDrawer,
        toggleDrawerKeepOpen: handleToggleDrawerKeepOpen,
        isDrawerMobile
      }
    }>
      {children}
    </DrawerContext.Provider>
  );
};
